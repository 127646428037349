@tailwind base;
@tailwind components;
@tailwind utilities;

.layout {
	@apply px-4 sm:px-16 w-full mx-auto;
}

.fonts-h2 {
	@apply leading-10 text-gray-700 font-bold;
	font-size: 2rem;
}

.fonts-body {
	@apply text-gray-50 leading-6 font-normal;
}

.card {
	border: 1px solid rgba(0, 0, 0, 0.06);
	border-radius: 8px;
	width: 100%;
	background: #ffffff;
}

.tag {
	color: #5a47cd !important;
	background: rgba(90, 71, 205, 0.03) !important;
	border-radius: 4px !important;
}

.ant-badge-count {
	margin-left: 8px;
	margin-bottom: 4px;
	height: 25px;
	width: 28px;
}

.ant-tabs-nav {
	margin-bottom: 0 !important;
}

.forward-modal > div {
	background-color: #f3f4f9 !important;
}
